'use client';

import React from 'react';
import '@splinetool/viewer';
import * as ReactDOM from 'react-dom/client';
import {ErrorBoundary} from 'react-error-boundary';
import './app.css';
import MobileNavButton from './components/MobileNavButton';

const ErrorComponent = ({children, content}: {
  children: React.ReactNode,
  content: string
}) => (
  <ErrorBoundary fallback={
    <h2 className="text-brand-blue sm:text-2.5xl text-lg sm:my-24 mt-9 leading-normal text-center max-w-155 mx-auto">{content}</h2>
  }>
    {children}
  </ErrorBoundary>
);

const mobileNavs = document.querySelectorAll('[data-react="mobileNav"]');
Array.from(mobileNavs).forEach(mobileNav => {
  if (mobileNav instanceof HTMLElement) {
    const root = ReactDOM.createRoot(mobileNav!);
    const reactProps = JSON.parse(mobileNav.dataset.props || '{}');

    root.render(
      <ErrorComponent
        content="">
        <MobileNavButton pages={reactProps.pages} contactUsLink={reactProps.contactUsLink}/>
      </ErrorComponent>
    );
  }
});


