import {Listbox, Transition} from '@headlessui/react';
import React from 'react';
import cls from 'classnames';
import PrimaryButton from './PrimaryButton';

const NavItem = ({page}: {page: Page}) => (
  <a href={page.link} className={cls(page.isCurrent ? 'text-white' : 'text-gray-nav','text-3.5xl transition duration-300 font-medium')}>
    {page.name}
  </a>
);

type Page = {
  name: string,
  link: string,
  isCurrent: boolean
}
const MobileNavButton = ({pages, contactUsLink}: { pages: Page[], contactUsLink }) => (
  <Listbox>
    {({open}) => (
      <div className="relative">
        <Listbox.Button>
          {open ?
            <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 fixed z-20 sm:right-16 sm:top-24 right-6 top-8" viewBox="0 0 16 16" fill="none">
              <path
              d="M15.5834 1.94425L14.0559 0.416748L8.00008 6.47258L1.94425 0.416748L0.416748 1.94425L6.47258 8.00008L0.416748 14.0559L1.94425 15.5834L8.00008 9.52758L14.0559 15.5834L15.5834 14.0559L9.52758 8.00008L15.5834 1.94425Z"
              fill="white"/>
            </svg>
            :
            <svg className="w-6.5 h-6.5 relative z-20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 26" fill="none">
              <path d="M23.25 6.5V8.66667H3.75V6.5H23.25ZM3.75 19.5H13.5V17.3333H3.75V19.5ZM3.75 14.0833H23.25V11.9167H3.75V14.0833Z"
                    fill="white"/>
            </svg>
          }
        </Listbox.Button>
        <Transition as="div"
                    show={open}
                    enter="transform transition ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transform transition ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    className="fixed top-0 right-0 bg-blackish shadow-home z-10 border-transparent border-2 w-screen h-screen overflow-y-hidden">
          <Listbox.Options>
            <div className="mx-8 h-screen">
              <div className="flex flex-col justify-between w-full pt-16 h-full">
                <div className="flex flex-col gap-7 pt-4">
                  {
                    pages.map((page: Page, index) => (
                      <NavItem page={page} key={index}/>
                    ))
                  }
                </div>
                <PrimaryButton href={contactUsLink} className="mb-14 justify-center">Get in touch</PrimaryButton>
              </div>
            </div>

          </Listbox.Options>
        </Transition>
      </div>
    )}
  </Listbox>
);


export default MobileNavButton;